/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const News = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Industry News | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xl posts" sx={{ paddingTop: `170px` }}>
        <h1>Industry News</h1>
        <div className="grid-x grid-margin-x grid-margin-y">
          {data.allWpSingleIndustryNews?.nodes?.map(e => {
            return (
              <div key={e?.id} className="large-4 medium-12 small-12 cell">
                <div className="posts__img_holder">
                  <a
                    href={e?.newsLinks?.linkUrl?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      fluid={
                        e?.featuredImage?.node?.localFile?.childImageSharp
                          ?.fluid
                      }
                      alt={e?.title}
                    />
                    <span className="news-button">Read more</span>
                  </a>
                </div>
                {/*{e.categories.nodes.map(c => {
                  return (
                    <div key={c.id} className="posts-categories">
                      <span>{c.name}</span>
                    </div>
                  )
                })}
              <br />*/}
                <span className="news-category">{e?.date}</span>
                <a
                  href={e?.newsLinks?.linkUrl?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>{e?.title}</h3>
                </a>

                <div dangerouslySetInnerHTML={{ __html: e?.content }}></div>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpSingleIndustryNews(sort: { fields: date, order: DESC }) {
      nodes {
        id
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        newsLinks {
          linkUrl {
            url
          }
        }
        date(formatString: "MMMM DD, YYYY")
        content
        title
      }
    }
  }
`

export default News
